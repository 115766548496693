import React from 'react'
import GeneralLayout from '../layouts/general'
import {graphql, StaticQuery} from "gatsby";

class IndexPage extends React.Component {
    render() {
        return (
            <StaticQuery query={query} render={data => {
                return (
                    <>
                        {!data && <p>Loading...</p>}
                        {data &&
                            <GeneralLayout pageTitle={data.contentfulPage.title}
                                           sections={data.contentfulPage.section}/>
                        }
                    </>
                )
            }}/>
        )
    }
}

export default IndexPage

const query = graphql`
{
  contentfulPage(slug: {eq: "home"}) {
    title
    slug
    section {
      ... on ContentfulRichText {
        id
        title
        color
        ctaLabel
        ctaLink
        content {
          raw
        }
        internal {
          type
        }
        hideTitle
        textCentered
        numberOfColumns
        image {
          id
          file {
            url
          }
        }
      }
      ... on ContentfulCounterPanel {
        id
        title
        items {
          id
          title
          to
          from
        }
        internal {
          type
        }
      }
      ... on ContentfulSubscriptionForm {
        id
        title
        notificationEmail
        lead {
          lead
        }
        backgroundImage {
          id
          file {
            url
          }
          internal {
            type
          }
          id
          title
        }
        internal {
          type
        }
      }
      ... on ContentfulPageHeader {
        id
        backgroundImages {
          id
          file {
            url
          }
          title
        }
        ctaLink
        ctaLabel
        ctaSecondaryLink
        ctaSecondaryLabel
        title
        size
        color
        shortLead{
          shortLead
        }
        internal {
          type
        }
      }
    }
  }
}
`;